body {
	overflow: hidden;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 0.3fr 0.3fr 3.1fr 0.3fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    width: 100%;
    height: 100vh;
}

.header-area {
	display: grid;
	grid-template-columns: 1.2fr 0.8fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	grid-area: 1 / 1 / 2 / 3;
}

.heading {
	grid-area: 1 / 1 / 2 / 2;

	display: grid;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;

	margin: 5px;

	height: 85px;
}

.menu {
	grid-area: 1 / 2 / 2 / 3;
	display: flex; /* Change from grid to flex */
	align-items: center;
	justify-content: space-between; /* Add this to space out the items */
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;

	height: 85px;
}

.search-area {
	grid-area: 2 / 1 / 3 / 3;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-auto-flow: row;
	height: 90px;
}

.emp-select {
	grid-area: 1 / 1 / 2 / 2;
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;

	height: 85px;
}

.create-employee-button {
	padding-left: 50px;
}

.eval-select {
	grid-area: 1 / 2 / 2 / 3;

	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;

	height: 85px;
}

.employee-area {
    grid-area: 3 / 1 / 4 / 2;
    display: grid;
    padding: 10px;
    align-content: start;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
    margin: 5px;
    max-height: calc(100vh - 300px);
    
}

.emp-details {
	grid-area: 1 / 1 / 2 / 2;
	display: block;
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;

	height: 170px;
}

.eval-details {
	grid-area: 2 / 1 / 3 / 2;
	display: block;
	padding-left: 10px;
	padding-right: 10px;

	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;

	padding-bottom: 20px;

	overflow-y: auto;
}

.report-area {
	display: flex;
	flex-direction: row; 
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	padding: 10px;
	align-content: flex-start;
	border-radius: 10px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;
	padding-bottom: 0;
	max-height: calc(100vh - 0px);
}

.radarBox {
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;
	aspect-ratio: 1 / 0.8;
	width: 47.1%;

	align-content: center;
	flex: 0 1 auto;
}

@media screen and (max-width: 1024px) {
	.radarBox {
		width: 100%;
	}
}

.footer-area {
	grid-area: 4 / 1 / 5 / 3;
	display: grid;
	align-items: center;
	justify-items: center;
	font-weight: bold;
	font-size: 1.2em;
	color: red;

	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 20px #b3b3b3, -10px -10px 20px #ffffff;
	margin: 5px;

	height: 75px;
}
